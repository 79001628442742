<template>
  <div style="color: #000">
    <br />
    ecommerce
    <br />
    <span class="svg-icon svg-icon-3" v-for="i in 100" :key="i">
      {{ `000${i}`.substring(`000${i}`.length - 3, `000${i}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/ecommerce/ecm${`000${i}`.substring(
          `000${i}`.length - 3,
          `000${i}`.length
        )}.svg`"
      />
    </span>
    <br />
    abstract
    <br />
    <span class="svg-icon svg-icon-3" v-for="i in 100" :key="i">
      {{ `000${i}`.substring(`000${i}`.length - 3, `000${i}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/abstract/abs${`000${i}`.substring(
          `000${i}`.length - 3,
          `000${i}`.length
        )}.svg`"
      />
    </span>
    <br />
    ART
    <br />
    <span class="svg-icon svg-icon-3" v-for="i in 100" :key="i">
      {{ `000${i}`.substring(`000${i}`.length - 3, `000${i}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/art/art${`000${i}`.substring(
          `000${i}`.length - 3,
          `000${i}`.length
        )}.svg`"
      />
    </span>
    <br />
    general
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/general/gen${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    coding
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/coding/cod${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    communication
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/communication/com${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    electronics
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/electronics/elc${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    graphs
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/graphs/gra${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    finance
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/finance/fin${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    files
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/files/fil${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    layouts
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/layouts/lay${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    maps
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/maps/map${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    medicine
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/medicine/med${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    technology
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/technology/teh${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    text
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/text/txt${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>
    <br />
    arrows
    <br />
    <span class="svg-icon svg-icon-3" v-for="j in 100" :key="`gen${j}`">
      {{ `000${j}`.substring(`000${j}`.length - 3, `000${j}`.length) }}
      <inline-svg
        :src="`media/icons/duotune/arrows/arr${`000${j}`.substring(
          `000${j}`.length - 3,
          `000${j}`.length
        )}.svg`"
      />
    </span>

    <br />
    COPY
    <br />
    <span class="svg-icon svg-icon-3">
      <inline-svg src="media/icons/duotune/maps/map000.svg" />
    </span>
  </div>
</template>
